import { ComponentProps } from 'react';

export const Router = (props: ComponentProps<'svg'>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none" {...props}>
    <path d="M2.5167 6.86971C2.9981 6.59342 3.16307 5.98158 2.88513 5.50306C2.60718 5.02455 1.99157 4.86056 1.51017 5.13685C1.02878 5.41313 0.86389 6.02497 1.14184 6.50349C1.41978 6.982 2.03531 7.14599 2.5167 6.86971ZM3.44546 7.41029C3.32178 7.53448 3.17957 7.64453 3.01993 7.73614C2.05711 8.28868 0.826038 7.96081 0.270018 7.00378C-0.285852 6.04673 0.0440508 4.82302 1.00686 4.27033C1.8192 3.80412 2.82254 3.96465 3.45008 4.60105L7.04653 2.53706C6.80591 1.67859 7.16768 0.734611 7.98001 0.268402C8.94282 -0.284142 10.1739 0.0437871 10.7299 1.00084C11.2859 1.95789 10.9559 3.1816 9.99307 3.73429C9.18082 4.20042 8.17748 4.03988 7.54986 3.40358L3.95341 5.46756C4.05375 5.82557 4.04928 6.19841 3.95113 6.54529L7.52515 8.59648C8.15282 7.96009 9.15614 7.79955 9.96836 8.26576C10.9312 8.81831 11.2611 10.0421 10.7052 10.9992C10.1493 11.9563 8.9182 12.2841 7.9553 11.7316C7.14297 11.2654 6.78119 10.3215 7.02182 9.46291L3.44546 7.41029ZM9.48987 2.86782C9.97126 2.59154 10.1362 1.97969 9.85829 1.50118C9.58035 1.02266 8.96473 0.858678 8.48334 1.13496C8.00194 1.41124 7.83697 2.02317 8.11491 2.50169C8.39286 2.9802 9.00847 3.1441 9.48987 2.86782ZM8.45866 10.8652C8.94006 11.1414 9.55567 10.9775 9.83362 10.499C10.1116 10.0204 9.9466 9.40858 9.46519 9.1323C8.9838 8.85602 8.36827 9.02 8.09033 9.49852C7.81238 9.97703 7.97726 10.5889 8.45866 10.8652Z" fill="currentColor" />
  </svg>
);
export const ThemedRouter = (props: ComponentProps<'svg'>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none" {...props}>
    <path d="M2.5167 6.86971C2.9981 6.59342 3.16307 5.98158 2.88513 5.50306C2.60718 5.02455 1.99157 4.86056 1.51017 5.13685C1.02878 5.41313 0.86389 6.02497 1.14184 6.50349C1.41978 6.982 2.03531 7.14599 2.5167 6.86971ZM3.44546 7.41029C3.32178 7.53448 3.17957 7.64453 3.01993 7.73614C2.05711 8.28868 0.826038 7.96081 0.270018 7.00378C-0.285852 6.04673 0.0440508 4.82302 1.00686 4.27033C1.8192 3.80412 2.82254 3.96465 3.45008 4.60105L7.04653 2.53706C6.80591 1.67859 7.16768 0.734611 7.98001 0.268402C8.94282 -0.284142 10.1739 0.0437871 10.7299 1.00084C11.2859 1.95789 10.9559 3.1816 9.99307 3.73429C9.18082 4.20042 8.17748 4.03988 7.54986 3.40358L3.95341 5.46756C4.05375 5.82557 4.04928 6.19841 3.95113 6.54529L7.52515 8.59648C8.15282 7.96009 9.15614 7.79955 9.96836 8.26576C10.9312 8.81831 11.2611 10.0421 10.7052 10.9992C10.1493 11.9563 8.9182 12.2841 7.9553 11.7316C7.14297 11.2654 6.78119 10.3215 7.02182 9.46291L3.44546 7.41029ZM9.48987 2.86782C9.97126 2.59154 10.1362 1.97969 9.85829 1.50118C9.58035 1.02266 8.96473 0.858678 8.48334 1.13496C8.00194 1.41124 7.83697 2.02317 8.11491 2.50169C8.39286 2.9802 9.00847 3.1441 9.48987 2.86782ZM8.45866 10.8652C8.94006 11.1414 9.55567 10.9775 9.83362 10.499C10.1116 10.0204 9.9466 9.40858 9.46519 9.1323C8.9838 8.85602 8.36827 9.02 8.09033 9.49852C7.81238 9.97703 7.97726 10.5889 8.45866 10.8652Z" fill="url(#paint0_linear_563_4051)" />
    <defs>
      <linearGradient id="paint0_linear_563_4051" x1="0.333333" y1="10" x2="11.3726" y2="9.88849" gradientUnits="userSpaceOnUse">
        <stop stopColor="#50D5FF" />
        <stop offset="1" stopColor="#EF35FF" />
      </linearGradient>
    </defs>
  </svg>
);
