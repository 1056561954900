import { ChainId } from '@jediswap/sdk'
import { URAQuoteResponse } from './state/routing/types'

export const quoteResult = {
  routing: 'CLASSIC',
  quote: {
    methodParameters: {
      calldata:
        '0x3593564c000000000000000000000000000000000000000000000000000000000000006000000000000000000000000000000000000000000000000000000000000000a00000000000000000000000000000000000000000000000000000000065b3696000000000000000000000000000000000000000000000000000000000000000040b000604000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000004000000000000000000000000000000000000000000000000000000000000008000000000000000000000000000000000000000000000000000000000000000e00000000000000000000000000000000000000000000000000000000000000200000000000000000000000000000000000000000000000000000000000000028000000000000000000000000000000000000000000000000000000000000000400000000000000000000000000000000000000000000000000000000000000002000000000000000000000000000000000000000000000000002386f26fc1000000000000000000000000000000000000000000000000000000000000000001000000000000000000000000000000000000000000000000000000000000000002000000000000000000000000000000000000000000000000002386f26fc1000000000000000000000000000000000000000000000000000000000000014ce63d00000000000000000000000000000000000000000000000000000000000000a00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000002bc02aaa39b223fe8d0a0e5c4f27ead9083c756cc20001f4a0b86991c6218b36c1d19d4a2e9eb0ce3606eb480000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000060000000000000000000000000a0b86991c6218b36c1d19d4a2e9eb0ce3606eb4800000000000000000000000027213e28d7fda5c57fe9e5dd923818dbccf71c47000000000000000000000000000000000000000000000000000000000000000f0000000000000000000000000000000000000000000000000000000000000060000000000000000000000000a0b86991c6218b36c1d19d4a2e9eb0ce3606eb480000000000000000000000000b95ec21579aee6ef7b712976bd86689d68b5a0800000000000000000000000000000000000000000000000000000000014ce63d',
      value: '0x2386f26fc10000',
      to: '0x3fC91A3afd70395Cd496C647d5a6CC9D4B2b7FAD',
    },
    blockNumber: '19089460',
    amount: '10000000000000000',
    amountDecimals: '0.01',
    quote: '21958917',
    quoteDecimals: '21.958917',
    quoteGasAdjusted: '10580826',
    quoteGasAdjustedDecimals: '10.580826',
    quoteGasAndPortionAdjusted: '10547888',
    quoteGasAndPortionAdjustedDecimals: '10.547888',
    gasUseEstimateQuote: '11378090',
    gasUseEstimateQuoteDecimals: '11.37809',
    gasUseEstimate: '245067',
    gasUseEstimateUSD: '11.395595179033723743',
    simulationStatus: 'SUCCESS',
    simulationError: false,
    gasPriceWei: '21132768356',
    route: [
      [
        {
          type: 'v3-pool',
          address: '0x88e6A0c2dDD26FEEb64F039a2c41296FcB3f5640',
          tokenIn: {
            chainId: ChainId.SN_GOERLI,
            decimals: '18',
            address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
            symbol: 'WETH',
          },
          tokenOut: {
            chainId: ChainId.SN_GOERLI,
            decimals: '6',
            address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
            symbol: 'USDC',
          },
          fee: '500',
          liquidity: '11008500594796006950',
          sqrtRatioX96: '1690306698369300557719652328373765',
          tickCurrent: '199371',
          amountIn: '10000000000000000',
          amountOut: '21925978',
        },
      ],
    ],
    routeString: '[V3] 100.00% = WETH -- 0.05% [0x88e6A0c2dDD26FEEb64F039a2c41296FcB3f5640] --> USDC',
    quoteId: 'd28aabe7-7f88-4984-95a9-69ff4ef08f4c',
    portionBips: 15,
    portionRecipient: '0x27213E28D7fDA5c57Fe9e5dD923818DBCcf71c47',
    portionAmount: '32938',
    portionAmountDecimals: '0.032938',
    requestId: 'a98ee5a8-66b4-425b-8883-f2d9e01ac43c',
    tradeType: 'EXACT_INPUT',
    slippage: 0.5,
  },
  requestId: 'a98ee5a8-66b4-425b-8883-f2d9e01ac43c',
  allQuotes: [
    null,
    {
      routing: 'CLASSIC',
      quote: {
        methodParameters: {
          calldata:
            '0x3593564c000000000000000000000000000000000000000000000000000000000000006000000000000000000000000000000000000000000000000000000000000000a00000000000000000000000000000000000000000000000000000000065b3696000000000000000000000000000000000000000000000000000000000000000040b000604000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000004000000000000000000000000000000000000000000000000000000000000008000000000000000000000000000000000000000000000000000000000000000e00000000000000000000000000000000000000000000000000000000000000200000000000000000000000000000000000000000000000000000000000000028000000000000000000000000000000000000000000000000000000000000000400000000000000000000000000000000000000000000000000000000000000002000000000000000000000000000000000000000000000000002386f26fc1000000000000000000000000000000000000000000000000000000000000000001000000000000000000000000000000000000000000000000000000000000000002000000000000000000000000000000000000000000000000002386f26fc1000000000000000000000000000000000000000000000000000000000000014ce63d00000000000000000000000000000000000000000000000000000000000000a00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000002bc02aaa39b223fe8d0a0e5c4f27ead9083c756cc20001f4a0b86991c6218b36c1d19d4a2e9eb0ce3606eb480000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000060000000000000000000000000a0b86991c6218b36c1d19d4a2e9eb0ce3606eb4800000000000000000000000027213e28d7fda5c57fe9e5dd923818dbccf71c47000000000000000000000000000000000000000000000000000000000000000f0000000000000000000000000000000000000000000000000000000000000060000000000000000000000000a0b86991c6218b36c1d19d4a2e9eb0ce3606eb480000000000000000000000000b95ec21579aee6ef7b712976bd86689d68b5a0800000000000000000000000000000000000000000000000000000000014ce63d',
          value: '0x2386f26fc10000',
          to: '0x3fC91A3afd70395Cd496C647d5a6CC9D4B2b7FAD',
        },
        blockNumber: '19089460',
        amount: '10000000000000000',
        amountDecimals: '0.01',
        quote: '21958917',
        quoteDecimals: '21.958917',
        quoteGasAdjusted: '10580826',
        quoteGasAdjustedDecimals: '10.580826',
        quoteGasAndPortionAdjusted: '10547888',
        quoteGasAndPortionAdjustedDecimals: '10.547888',
        gasUseEstimateQuote: '11378090',
        gasUseEstimateQuoteDecimals: '11.37809',
        gasUseEstimate: '245067',
        gasUseEstimateUSD: '11.395595179033723743',
        simulationStatus: 'SUCCESS',
        simulationError: false,
        gasPriceWei: '21132768356',
        route: [
          [
            {
              type: 'v3-pool',
              address: '0x88e6A0c2dDD26FEEb64F039a2c41296FcB3f5640',
              tokenIn: {
                chainId: ChainId.SN_GOERLI,
                decimals: '18',
                address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
                symbol: 'WETH',
              },
              tokenOut: {
                chainId: ChainId.SN_GOERLI,
                decimals: '6',
                address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
                symbol: 'USDC',
              },
              fee: '500',
              liquidity: '11008500594796006950',
              sqrtRatioX96: '1690306698369300557719652328373765',
              tickCurrent: '199371',
              amountIn: '10000000000000000',
              amountOut: '21925978',
            },
          ],
        ],
        routeString: '[V3] 100.00% = WETH -- 0.05% [0x88e6A0c2dDD26FEEb64F039a2c41296FcB3f5640] --> USDC',
        quoteId: 'd28aabe7-7f88-4984-95a9-69ff4ef08f4c',
        portionBips: 15,
        portionRecipient: '0x27213E28D7fDA5c57Fe9e5dD923818DBCcf71c47',
        portionAmount: '32938',
        portionAmountDecimals: '0.032938',
        requestId: 'a98ee5a8-66b4-425b-8883-f2d9e01ac43c',
        tradeType: 'EXACT_INPUT',
        slippage: 0.5,
      },
    },
  ],
}
